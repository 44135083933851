<template>
  <div class="pageContent">
    <searchHeader />
    <div class="productionDetails">
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="proInfo">
            <div class="proImage">
              <img :src="orderDetails.img" v-if="orderDetails.img !== '--'" />
            </div>
            <div class="proContent">
              <p class="commodity">{{ orderDetails.commodity }}</p>
              <div class="desc">
                <p>
                  <span class="item">
                    <span class="itemTitle">商品分类：</span>
                    <span class="itemText">{{ orderDetails.className }}</span>
                  </span>
                </p>
                <p>
                  <span class="item">
                    <span class="itemTitle">商品属性：</span>
                    <span class="itemText" v-for="(item,index) of orderDetails.specificationsName"
                    :key="index">{{ item.label }}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  </span>
                </p>
                <p>
                  <span class="item">
                    <span class="itemTitle">挂单类型：</span>
                    <span class="itemText">{{orderDetails.sourceId === 'M' ? '初始挂单' : '转让挂单'}}</span>
                  </span>
                </p>
                <p>
                  <span class="item">
                    <span class="itemTitle">初始交收日：</span>
                    <span class="itemText">{{orderDetails.deliveryTime | DateFormateDay}}</span>
                  </span>
                </p>
                <p>
                  <span class="item">
                    <span class="itemTitle">最后点价日：</span>
                    <span class="itemText">{{orderDetails.somePriceTime | DateFormateDay}}</span>
                  </span>
                </p>
                <p>
                  <span class="item">
                    <span class="itemTitle">基差价：</span>
                    <span class="itemText price">{{ orderDetails.basisPrice }}元/{{orderDetails.weightUnitName}}</span>
                  </span>
                </p>
                <p>
                  <span class="item">
                    <span class="itemTitle">挂单重量：</span>
                    <span class="itemText">{{ orderDetails.hangWeight }}{{orderDetails.weightUnitName}}</span>
                  </span>        
                  <span class="item">
                    <span class="itemTitle">可摘单重量：</span>
                    <span class="itemText">{{orderDetails.unsettledWeight - orderDetails.frozenWeight }}{{orderDetails.weightUnitName}}</span>
                  </span>
                </p>
              </div>
            </div>
            <div class="proOrderOperate">
              <el-button type="danger" @click="showOrderDialog(orderDetails)">立即摘单</el-button>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <!-- 详情标红框内：商品分类、商品属性、挂单类型、初始交收日、最后点价日、基差价、初始基差价、挂单重量、数量、企业名称、业务负责人、联系电话、交收仓库、、另外冒号统一用中文字符 -->
        <el-col :span="24">
          <div class="proDetails">
            <p class="proDetailsTitle">商品详情</p>
            <div class="proDetailsContent">
              <el-row :gutter="20">
                <el-col :span="6">
                  <p>商品分类：{{ orderDetails.className }}</p>
                </el-col>
                <el-col :span="6" v-for="(item,index) of orderDetails.specificationsName" :key="index">
                  <p>
                    <span>
                      <span>商品属性：</span>
                      <span>{{item.label}}</span>
                    </span>
                  </p>
                </el-col>
                <el-col :span="6">
                  <p>挂单类型：{{orderDetails.sourceId === 'M' ? '初始挂单' : '转让挂单'}}</p>
                </el-col>
                <el-col :span="6">
                  <p>初始交收日：{{orderDetails.deliveryTime | DateFormateDay}}</p>
                </el-col>
                <el-col :span="6">
                  <p>最后点价日：{{orderDetails.somePriceTime | DateFormateDay}}</p>
                </el-col>
                <el-col :span="6">
                  <p>基差价：{{orderDetails.basisPrice ? orderDetails.basisPrice:'0' }}元/{{orderDetails.weightUnitName}}</p>
                </el-col>
                <el-col :span="6" v-if="orderDetails.sourceId === 'F'">
                  <p>初始基差价：{{orderDetails.orderBasisPrice ? orderDetails.orderBasisPrice: 0 }}元/{{orderDetails.weightUnitName}}</p>
                </el-col>
                <el-col :span="6">
                  <p>挂单重量：{{orderDetails.hangWeight ? orderDetails.hangWeight:'0' }}{{orderDetails.weightUnitName}}</p>
                </el-col>
                <el-col :span="6">
                  <p>是否接受议价：{{orderDetails.bargainingStatus === "T"  ? '可议价' : '不可议价' }}</p>
                </el-col>
                <el-col :span="6">
                  <p>交易方式：{{ orderDetails.tradeWay === "A"  ? '整批' : '分销' }}</p>
                </el-col>
                <el-col :span="6">
                  <p>数量：{{orderDetails.quantity ? orderDetails.quantity:'---' }}</p>
                </el-col>            
                <el-col :span="6">
                  <p>业务负责人：{{orderDetails.businessName ? orderDetails.businessName:'---' }}123</p>
                </el-col>
                <el-col :span="6">
                  <p>联系电话：{{ orderDetails.businessPhone ?orderDetails.businessPhone:'--'}}</p>
                </el-col>
                <el-col :span="6">
                  <p>交收仓库：{{ orderDetails.storageName ? orderDetails.storageName : '---'}}</p>
                </el-col> 
                 <el-col :span="8"> 
                  <p>企业名称：{{ orderDetails.firmEnterpriseName ? orderDetails.firmEnterpriseName : '---'}}</p>
                </el-col>          

              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 下单弹窗 -->
    <confirmOrder ref="confirmOrder" />
  </div>
</template>
<script>
import searchHeader from "../base/header/searchHeader";
import confirmOrder from "./confirmOrder";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      proCount: 1,
      priceStep: [],
      orderDetails: {}
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  components: {
    searchHeader,
    confirmOrder
  },
  mounted() {
    this.getOrderDetails();
  },
  methods: {
    //商品详情
    getOrderDetails() {
      const that = this;
      protocolFwd.param_basisOrderInfo.param.hangResourcesId =this.$route.params.id;
      http.postFront(protocolFwd.param_basisOrderInfo).then(response => {
        let that = this;
        const { code, message, value } = response.data;
        if (code == 0) {
          value['content']['0'].specificationsName = this.attributeTrans(
            value['content']['0'].specificationsName
          );
          // value.zjPrice = util.floatMul(value.price, value.hangWeight);
          this.orderDetails = value['content']['0'];
          if (value.tradeWay == "P") {
            that.getStepPrice(value.hangResourcesId);
          }
          for (let i in this.orderDetails) {
            if (!this.orderDetails[i] && this.orderDetails[i] != 0) {
              this.orderDetails[i] = "--";
            }
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getStepPrice(id) {
      protocolFwd.param_queryPhaseHangPrice.param.hangResourceId = this.$route.params.id;
      http.postFront(protocolFwd.param_queryPhaseHangPrice).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.priceStep = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    showOrderDialog(row) {
       if (this.sessionInfoGetter.isLogin == "true") {
        if (this.sessionInfoGetter.firmId == row.hangFirmId) {
          this.$EL_MESSAGE("不允许自买自卖");
          return;
        }
          this.$refs.confirmOrder.showDialog(row)
      } else {
        this.$EL_MESSAGE("请先登录");
        this.$router.push("/login");
      }
    
      // this.$refs.confirmOrder.showDialog(row.hangResourcesId,row.unsettledWeight,row.basisPrice);
 
    },    
  }
};
</script>
<style lang="scss" scoped>
.productionDetails {
  width: 1200px;
  // width: 80%;
  // min-width: 1300px;
  margin: 10px auto;
}
.productionDetails .proInfo,
.productionDetails .proDetails {
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
}
.proInfo {
  position: relative;
  overflow: hidden;
}
.proInfo .proImage {
  float: left;
  width: 350px;
  height: 260px;
}
.proInfo .proImage img {
  max-width: 100%;
  max-height: 100%;
}
.proInfo .proImage p {
  line-height: 260px;
  text-align: center;
  color: #ffffff;
  font-size: 26px;
}
.proInfo .proContent {
  line-height: 30px;
}
.proInfo .proContent .commodity {
  font-size: 16px;
  font-weight: 700;
}
.proInfo .proContent .desc * {
  color: #666666;
}
.proInfo .proContent .desc .item {
  margin-right: 20px;
}
.proInfo .proContent .desc .item .price,
.proInfo .proContent .desc .item .zjPrice {
  font-weight: 700;
  color: $commonThemeColor;
}
.proInfo .proOrderOperate {
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.proDetails {
  margin-top: 20px;
}
.proDetails .proDetailsTitle {
  margin-bottom: 10px;
  font-size: 16px;
  color: $commonThemeColor;
}
.proDetails .proDetailsContent {
  line-height: 35px;
  color: #666666;
}
.setStyle {
  font-style: normal;
}
</style>

